
import { defineComponent, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'OuterPasswordComplete',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const edited = route.params.edited === 'true'
    onMounted(async () => {
      if (!edited) {
        await router.push('/dms/outer/passwordEdit')
      }
    })
    return {
    }
  },
})
