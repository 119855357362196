
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterPasswordComplete from '@/components/organisms/dms/OuterPasswordComplete/OuterPasswordComplete.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
export default defineComponent({
  components: {
    OuterPasswordComplete,
    DssIcon,
  },
  setup () {
    const router = useRouter()
    return {
      router,

      async toTop () {
        await router.push('/dms/outer')
      },
    }
  },
})
